import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CdkDragDrop, DragDropModule, moveItemInArray} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgFor, NgIf} from "@angular/common";
import {PlannerRepository} from "../../../repositories/planner.repository";
import {ActivatedRoute} from "@angular/router";
import {STEP_STATUS} from "../../../models/enums";
@Component({
  selector: 'app-initial-plan',
  standalone: true,
  imports: [DragDropModule,ReactiveFormsModule,NgFor,FormsModule,NgIf],
  templateUrl: './initial-plan.component.html',
  styleUrl: './initial-plan.component.css'
})
export class InitialPlanComponent {
  toolingId: string | null = "";
  tempData: string | null = "";
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();

  constructor(private plannerRepository: PlannerRepository,private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('plannerId');
    });
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.step.action.planSteps, event.previousIndex, event.currentIndex);
    this.step.action.planSteps[event.currentIndex].seq=event.currentIndex;
    this.step.action.planSteps[event.previousIndex].seq=event.previousIndex;
    console.log(event.previousIndex, event.currentIndex);

  }

  editItem(item: any) {
    this.tempData=item.rawData;
    item.isEditing = true;
  }

  saveItem(item: any,index: any) {
    delete item.isEditing;
  }

  discardEdit(item: any) {
    item.isEditing = false;
    item.rawData = this.tempData;
  }
  next() {
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.plannerRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          this.loadTooling.emit();
        }
      );
    }
  }

  protected readonly STEP_STATUS = STEP_STATUS;
}


export class Templates {
  templates: Templates[];
  constructor(data: any)
  {
    this.templates = data.templates.map((msg: any) => new Template(msg));
  }
}
export class Template {
  templateId: string ='';
  createdOn: Date | null =  null;
  version: number =0;
  label:string = '';
  rawData: string ='';
  description: string='';

  constructor(data: any) {
    this.templateId = data.templateId;
    this.createdOn = data.createdOn;
    this.version = data.version;
    this.label= data.label
    this.rawData = data.rawData;
    this.description = data.description;
  }
}

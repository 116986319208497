import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toKb',
  standalone: true
})
export class ToKbPipe implements PipeTransform {

  transform(value: number): string {
    if (value == null) {
      return '';
    }
    const mbValue = value / 1024;
    return mbValue.toFixed(2); // Convert to MB and keep 2 decimal places
  }

}

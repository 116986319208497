import { Component, OnInit } from '@angular/core';
import { KeycloakService } from '../../auth/keycloak.service';

@Component({
  selector: 'app-login',
  template: `<div>Redirecting to login...</div>`
})
export class LoginComponent implements OnInit {

  constructor(private keycloakService: KeycloakService) {}

  ngOnInit() {
    this.keycloakService.login();
  }
}

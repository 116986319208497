import {Component, EventEmitter, Input, Output} from '@angular/core';
import {JsonPipe, NgClass, NgFor} from "@angular/common";
import {AvailableUserChoices, MessageStatus, STEP_TYPE, ToolingTypeToLink} from "../../../models/enums";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {lastValueFrom} from "rxjs";
import {ChatRepository} from "../../../repositories/chat.repository";
import {LoaderService} from "../../../services/loader.service";
import {RagRepository} from "../../../repositories/rag.repository";
import {Utilities} from "../../../utilities/utilities";

@Component({
  selector: 'app-tool-selector',
  standalone: true,
  imports: [NgFor, TranslateModule, JsonPipe, NgClass],
  templateUrl: './tool-selector.component.html',
  styleUrl: './tool-selector.component.css'
})
export class ToolSelectorComponent {
  @Input() message: any;
  @Input() chatId: any;
  @Output() functionCallEvent = new EventEmitter<{ functionName: string, args: any[] }>();
  constructor(private chatRepository: ChatRepository, private router: Router, private route: ActivatedRoute,public loaderService: LoaderService, public ragRepository: RagRepository) {
  }
  async ngOnInit(): Promise<void> {

  }

  async toolSelector(tool: AvailableUserChoices,messageId: string): Promise<void> {
   let chatPut = await lastValueFrom(this.chatRepository.updateMessage(this.chatId,messageId,tool));
    if(chatPut.nextMessageId){
      if(tool==AvailableUserChoices.RAG_EDITOR || tool==AvailableUserChoices.PLANNER_EDITOR) {
        this.loaderService.show();
        await new Promise(resolve => setTimeout(resolve, 200));
        this.message = await lastValueFrom(this.chatRepository.getMessage(this.chatId,messageId));
        await this.router.navigate([`/chat/${this.chatId}/${Utilities.convertTypeToUrl(tool)}/${this.message.tooling.toolingId}`]);
      }
      else{
        let new_message= await lastValueFrom(this.chatRepository.getMessage(this.chatId, chatPut.nextMessageId));
        this.functionCallEvent.emit({ functionName: 'loadMessages', args: [this.chatId] });
        console.log(new_message);
      }

    }
  }

  protected readonly MessageStatus = MessageStatus;

  jumpToTool(userChoice: AvailableUserChoices, toolingId: string) {
    this.router.navigate([`/chat/${this.chatId}/${Utilities.convertTypeToUrl(userChoice)}/${this.message.tooling.toolingId}`]);
  }

  protected readonly STEP_TYPE = STEP_TYPE;
}


import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule,Validators} from "@angular/forms";
import {JsonPipe, NgFor, NgIf} from "@angular/common";
import {PlannerRepository} from "../../../repositories/planner.repository";
import {ActivatedRoute} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {STEP_STATUS} from "../../../models/enums";

@Component({
  selector: 'app-plugin-action',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, NgFor, TranslateModule, JsonPipe],
  templateUrl: './plugin-action.component.html',
  styleUrl: './plugin-action.component.css'
})
export class PluginActionComponent {
  activeTab = 'url';

  urlForm: FormGroup;
  paramsForm: FormGroup;
  methodForm: FormGroup;
  headersForm: FormGroup;
  bodyForm: FormGroup;
  isValidJson: boolean = true;
  toolingId: string | null = "";
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();




  constructor(private fb: FormBuilder,private plannerRepository: PlannerRepository,private route: ActivatedRoute) {
    this.urlForm = this.fb.group({
      url: ['']
    });

    this.paramsForm = this.fb.group({
      params: this.fb.array([])
    });

    this.headersForm = this.fb.group({
      headers: this.fb.array([])
    });
    this.methodForm = this.fb.group({
      method: ['']
    });
    this.bodyForm = this.fb.group({
      body: ['']
    });

  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('plannerId');
    });
    this.urlForm = this.fb.group({
      url: [this.step.action.url]
    });
    this.bodyForm = this.fb.group({
      body: [this.formatJsonString(this.step.action.body)]
    });
    this.methodForm = this.fb.group({
      method: [this.step.action.method]
    });
    this.populateHeaders();
    this.headersForm.valueChanges.subscribe(() => {
      this.updateHeadersObject();
    });
    this.populateParams();
    this.paramsForm.valueChanges.subscribe(() => {
      this.updateParamsObject();
    });
    this.bodyForm.get('body')?.valueChanges.subscribe(value => {
      this.updateJsonString(value);
    });
    this.methodForm.get('method')?.valueChanges.subscribe((selectedMethod: string) => {
      this.step.action.method = selectedMethod;
    });
  }

  updateJsonString(value: string): void {
    try {
      // Try to parse the formatted JSON input
      const parsed = JSON.parse(value);
      this.step.action.body = JSON.stringify(parsed);  // Convert back to string and store
      this.isValidJson = true;
    } catch (e) {
      this.isValidJson = false;  // Mark invalid if JSON can't be parsed
    }
  }
  formatJsonString(jsonString: string): string {
    try {
      const jsonObj = JSON.parse(jsonString);  // Parse the JSON string
      return JSON.stringify(jsonObj, null, 2); // Format with indentation for display
    } catch (e) {
      this.isValidJson = false;  // Invalid JSON format
      return jsonString;  // Return as-is if parsing fails
    }
  }
  populateParams() {
    const paramsArray = this.paramsForm.get('params') as FormArray;
    Object.entries(this.step.action.params).forEach(([key, value]) => {
      paramsArray.push(this.fb.group({
        key: [key, Validators.required],
        value: [value, Validators.required]
      }));
    });
  }

  get params() {
    return this.paramsForm.get('params') as FormArray;
  }

  addParams() {
    const paramsArray = this.paramsForm.get('params') as FormArray;
    paramsArray.push(this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required]
    }));
    this.updateParamsObject();
  }

  // Method to remove a header
  removeParams(index: number) {
    const paramsArray = this.headersForm.get('params') as FormArray;
    paramsArray.removeAt(index);
    this.updateParamsObject();
  }

  updateParamsObject() {
    const paramsArray = this.paramsForm.get('params') as FormArray;
    const updatedParams: { [key: string]: string } = {};

    paramsArray.controls.forEach(control => {
      const key = control.get('key')?.value;
      const value = control.get('value')?.value;
      if (key && value) {
        updatedParams[key] = value;
      }
    });
    this.step.action.params = updatedParams;
    console.log("Updated Params Object: ", this.step.action.params);
  }
  getParamsAsObject() {
    return this.step.action.params;
  }

  /******************************************/

  populateHeaders() {
    const headersArray = this.headersForm.get('headers') as FormArray;

    console.log(this.step.action.headers);
    Object.entries(this.step.action.headers).forEach(([key, value]) => {
      headersArray.push(this.fb.group({
        key: [key, Validators.required],
        value: [value, Validators.required]
      }));
    });
  }

  get headers() {
    return this.headersForm.get('headers') as FormArray;
  }

  addHeader() {
    const headersArray = this.headersForm.get('headers') as FormArray;
    headersArray.push(this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required]
    }));
    this.updateHeadersObject();
  }

  // Method to remove a header
  removeHeader(index: number) {
    const headersArray = this.headersForm.get('headers') as FormArray;
    headersArray.removeAt(index);
    this.updateHeadersObject();
  }

  updateHeadersObject() {
    const headersArray = this.headersForm.get('headers') as FormArray;
    const updatedHeaders: { [key: string]: string } = {};

    headersArray.controls.forEach(control => {
      const key = control.get('key')?.value;
      const value = control.get('value')?.value;
      if (key && value) {
        updatedHeaders[key] = value;
      }
    });

    // Update the original initialHeaders object
    this.step.action.headers = updatedHeaders;
    console.log("Updated Headers Object: ", this.step.action.headers);
  }
  getHeadersAsObject() {
    return this.step.action.headers;
  }


  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  next() {
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.plannerRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          this.loadTooling.emit();
        }
      );
    }
  }

  protected readonly STEP_STATUS = STEP_STATUS;
}

import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {ToastrService} from "ngx-toastr";


@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(private toastr: ToastrService,
              private translate: TranslateService) {
  }
  showSuccess(title:string,message:string) {
    this.toastr.success(this.translate.instant('general.success'),   this.translate.instant(message));
  }

  showError(title:string,message:string) {
    this.toastr.error(this.translate.instant('general.error'),   this.translate.instant(message));
  }

  showInfo(title:string,message:string) {

    this.toastr.info(this.translate.instant('general.info'),   this.translate.instant(message));
  }

  showWarning(title:string,message:string) {
    this.toastr.warning(this.translate.instant('general.warning'),   this.translate.instant(message));
  }

}

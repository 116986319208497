<div class="row mt-3 mr-3">
  <div class="col-12 text-right">
    <button class="btn btn-primary" (click)="openUploadDialog()">{{'plugin.new' | translate}}</button>
  </div>
</div>
<div style="margin:30px" class="tableContainer">

  <table *ngIf="this.pluginList" datatable [dtOptions]="dtOptions" class="row-border hover">
    <thead>
    <tr>
      <th>{{"plugin.pluginId" | translate}}</th>
      <th>{{"plugin.displayName" | translate}}</th>
      <th>{{"plugin.modelName" | translate}}</th>
      <th>{{"plugin.processed" | translate}}</th>
      <th>{{"plugin.created" | translate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let plugin of this.pluginList?.plugins">
      <td class="font-weight-bold">{{ plugin.pluginId }}</td>
      <td>{{ plugin.displayName }}</td>
      <td>{{ plugin.modelName }}</td>
      <td>{{ plugin.processed }}</td>
      <td>{{ plugin.createdOn | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      <td>
        @if (plugin.enabled){
        <fa-icon [icon]="faPause" (click)=unpublishPlugin(plugin.pluginId)></fa-icon>
        } @else {
        <fa-icon [icon]="faPlay" (click)=publishPlugin(plugin.pluginId)></fa-icon>
        }
        <fa-icon [icon]="faPenToSquare" (click)="openUploadDialog(plugin.pluginId)"></fa-icon>
        <fa-icon [icon]="faTrash" (click)="showSwal( plugin.pluginId )"></fa-icon>
      </td>
    </tr>
    </tbody>
  </table>

</div>

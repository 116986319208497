<div class="chat-bubble-container">
  <div class="chat-bubble ml-4">
    <div class="row w-100">

      <div class="col-6 text-right">
        <div class="chatLoader"></div>
      </div>
      <div class="col-6">
        <i class="bi bi-x-circle mr-1 deleteStep" (click)="deleteStep()"  style="cursor: pointer"></i>
      </div>
    </div>
  </div>
</div>

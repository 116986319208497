import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem, NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {JsonPipe, NgIf} from "@angular/common";
import {STEP_STATUS} from "../../../models/enums";
import {RagRepository} from "../../../repositories/rag.repository";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
declare var bootstrap: any;
@Component({
  selector: 'app-answer-generation',
  standalone: true,
  imports: [
    TranslateModule,
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    FormsModule,
    JsonPipe,
    NgIf
  ],
  templateUrl: './answer-generation.component.html',
  styleUrl: './answer-generation.component.css'
})
export class AnswerGenerationComponent {
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();
  protected readonly STEP_STATUS = STEP_STATUS;
  processedText: SafeHtml | undefined;
  toolingId: string | null = "";
  constructor(private modalService: NgbModal, private ragRepository: RagRepository, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
  }
  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('ragId');
    });
    this.processedText = this.addTooltipsToGuids(this.step.action?.rawData);
  }
  ngAfterViewInit(): void {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  addTooltipsToGuids(text: string): SafeHtml {
    const guidRegex = /\[([0-9a-fA-F-]{36})\]/g;
    const processedText = text.replace(guidRegex, (match, guid) => {
      const tooltipData = this.getDataForGuid(guid);
      return `<span class="tooltip-guid" data-bs-toggle="tooltip" title="${tooltipData}">[${guid}]</span>`;
    });
    return this.sanitizer.bypassSecurityTrustHtml(processedText);
  }
  getDataForGuid(guid: string): string {

    if (this.step?.action?.references) {    console.log(this.step?.action?.references);
      const matchingReference = this.step.action.references.find((ref: any) => ref.partitionId === guid);
      if (matchingReference) {
        return matchingReference.summary || 'Summary not available';
      }
    }
    return 'No matching reference found for GUID';
  }
  next(){
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.ragRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { KeycloakService } from './keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private keycloakService: KeycloakService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.keycloakService.isLoggedIn().pipe(
      map(authenticated => {
        if (!authenticated) {
          console.log("authenticate false")
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      })
    );
  }
}

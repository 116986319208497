import { Component } from '@angular/core';
import {ChatComponentComponent} from "../../components/chat-component/chat-component.component";
import {ChatSideBarComponent} from "../../components/chat-side-bar/chat-side-bar.component";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrl: './chat-page.component.css',
  standalone: true,
  imports: [ChatComponentComponent,ChatSideBarComponent,CommonModule],
})
export class ChatPageComponent {
  sidebarExpanded = true;
}

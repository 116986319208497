import {Component, Input} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn, Validators
} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiRepository} from "../../repositories/api.repository";
import {lastValueFrom} from "rxjs";
import {OpenapiParserService} from "../../services/openapi-parser.service";
import {PluginRepository} from "../../repositories/plugin.repository";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";
import {AUTHMETHODS} from "../../models/enums";

@Component({
  selector: 'app-add-plugin',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule],
  templateUrl: './add-plugin.component.html',
  styleUrl: './add-plugin.component.css'
})
export class AddPluginComponent {
  uploadForm: FormGroup;
  openApiParsed: any;
  authMethods = Object.values(AUTHMETHODS);
  @Input() plugin!: any;


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, public notificationService: NotificationService, private apiRepository: ApiRepository, private openapiParserService: OpenapiParserService, private pluginRepository: PluginRepository, private router: Router) {
    this.uploadForm = this.fb.group({
      displayName: ['',Validators.required],
      modelName: ['',Validators.required],
      modelDescription: ['',Validators.required],
      humanDescription: ['',Validators.required],
      certificatePublicKey: [''],
      certificatePrivateKey: [''],
      authHeader : [''],
      authKey: [''],
      authMethod: ['',Validators.required],
      url: ['',Validators.required],
      openapi: ['',Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.plugin) {
      this.uploadForm.patchValue({
        displayName: this.plugin.displayName || '',
        modelName: this.plugin.modelName || '',
        modelDescription: this.plugin.modelDescription || '',
        humanDescription: this.plugin.humanDescription || '',
        certificatePublicKey: this.plugin.certificatePublicKey || '',
        certificatePrivateKey: this.plugin.certificatePrivateKey || '',
        authHeader: this.plugin.authHeader || '',
        authKey: this.plugin.authKey || '',
        authMethod: this.plugin.authMethod || '',
        url: this.plugin.url || '',
        openapi: this.plugin.openapi || ''
      });
      /*   this.openApiParsed =await this.openapiParserService.parseOpenApiSpecToYaml(this.plugin.openapi);
         console.log(this.openApiParsed);
         console.log(this.openapiParserService.getTitle(this.openApiParsed));
         console.log(this.openapiParserService.getDescription(this.openApiParsed));
         console.log(this.openapiParserService.getVersion(this.openApiParsed));*/
    }
  }



  async parseSpec(openApiSpec: string): Promise<void> {
    try {
      const parsedSpec = await this.openapiParserService.parseOpenApiSpecToYaml(openApiSpec);
      this.openApiParsed = parsedSpec;
    } catch (error) {
      console.error('Error parsing OpenAPI Specification:', error);
    }
  }

  async callOpenApiDefinition() {
    const {url} = this.uploadForm.value;
    this.apiRepository.setUrl(url);
    let openApiDescription = await lastValueFrom(this.apiRepository.getAny());
    console.log(openApiDescription)

  }

  onSubmit(): void {

    const {displayName} = this.uploadForm.value;
    const {modelName} = this.uploadForm.value;
    const {modelDescription} = this.uploadForm.value;
    const {humanDescription} = this.uploadForm.value;
    const {certificatePublicKey} = this.uploadForm.value;
    const {certificatePrivateKey} = this.uploadForm.value;
    const {authMethod} = this.uploadForm.value;
    const {url} = this.uploadForm.value;
    const {openapi} = this.uploadForm.value;
    if (this.uploadForm.valid) {


      if (this.plugin.pluginId) {
        this.pluginRepository.patchPlugin(this.plugin.pluginId, displayName, modelName, modelDescription, humanDescription, certificatePrivateKey, certificatePublicKey, authMethod, url, openapi).subscribe(
          (response) => {
            console.log('Upload successful', response);
            this.notificationService.showSuccess("Siker", "plugin.successPatch");
            this.activeModal.close(true);
            this.router.navigate([this.router.url]).then(() => {
              window.location.reload();
            });
          },
          (error) => {
            console.error('Upload failed', error);
          }
        );
      } else {
        this.pluginRepository.newPlugin(displayName, modelName, modelDescription, humanDescription, certificatePrivateKey, certificatePublicKey, authMethod, url, openapi).subscribe(
          (response) => {
            console.log('Upload successful', response);
            this.notificationService.showSuccess("Siker", "plugin.successAddNew");
            this.activeModal.close(true);
            /* this.router.navigate([this.router.url]).then(() => {
               window.location.reload();
             });*/
          },
          (error) => {
            console.error('Upload failed', error);
          }
        );
      }
    }
  }

  onClose(): void {
    this.activeModal.close();
  }
  get authMethod() {
    return this.uploadForm.get('authMethod')!.value;
  }
}

import {Component, HostListener} from '@angular/core';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from '../utils/constants';
import {LanguageService} from "./services/language.service";
import {LoaderService} from "./services/loader.service";
import {TimerService} from "./services/timer.service";
import {HeaderComponent} from "./components/header/header.component";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'arrowhead-copilot';

  constructor(public translate: TranslateService,public loaderService: LoaderService,private timerService: TimerService) {
    translate.addLangs(Constants.SYSTEM_LANGUAGES);
    translate.setDefaultLang(Constants.DEFAULT_LANGUAGE);
  }
  @HostListener('window:click')
  @HostListener('window:keypress')
  handleUserActivity() {
    this.timerService.resetTimer();
  }
  async ngOnInit(): Promise<void> {
    const lang = LanguageService.getClientLanguage();
    this.translate.use(lang);
  }
}

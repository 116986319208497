import {ChangeDetectorRef, Component, Input, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DocumentRepository} from "../../../repositories/document.repository";
import {NotificationService} from "../../../services/notification.service";
import {Router} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {JsonPipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-partition-image-edit',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, NgIf, JsonPipe],
  templateUrl: './partition-image-edit.component.html',
  styleUrl: './partition-image-edit.component.css'
})
export class PartitionImageEditComponent {
  @Input() partition!: any ;
  @Input() documentId!: any ;
  @Input() partitionId!: any ;
  uploadForm: FormGroup;
  base64Image: string ="";
  selectedFile: File | undefined = undefined;
  constructor(
    public activeModal: NgbActiveModal,
    private docRepository: DocumentRepository,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.uploadForm = this.fb.group({
      summary: ['', Validators.required]
    });
  }

  ngOnInit(): void {

    this.base64Image = `data:image/jpeg;base64,${this.partition.rawData}`;
    this.uploadForm.patchValue({
      summary: this.partition.semanticSummary|| '',
    });
  }


  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    this.uploadForm.patchValue({ fileInput: this.selectedFile });
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        this.base64Image = reader.result as string;
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }
  removeDataUrlPrefix(base64String: string): string {
    return base64String.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
  }
  onSubmit(): void {
    const {summary} = this.uploadForm.value;
    if (this.uploadForm.valid) {
      this.docRepository.patchPartition(this.documentId,this.partitionId, this.partition.elementType, this.removeDataUrlPrefix(this.base64Image), summary).subscribe(
        (response) => {
          console.log('Upload successful', response);
          this.notificationService.showSuccess("Siker", "document.partition.successPatch");
          this.activeModal.close(true);
           this.router.navigate([this.router.url]).then(() => {
             window.location.reload();
           });
        },
        (error) => {
          console.error('Upload failed', error);
        }
      );
    }
  }

  onClose(): void {
    this.activeModal.close();
  }
}

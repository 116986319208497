<div class="planner-container" #plannerContainer>
@if (toolingSteps && toolingSteps.length == tooling?.toolingSteps?.length && !isLoading) {

    <div ngbAccordion class="m-4 ">
      @for (step of toolingSteps; track step; ) {

        <div ngbAccordionItem [collapsed]="$index!=toolingSteps.length-1">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="arrowhead-accordion-header">
              <div class="row w-100">
                <div class="col-6">
                <span><i
                  [ngClass]="{
                            'bi-bezier': step.stepType === STEP_TYPE.USER_INTENT,
                            'bi-plug': step.stepType === STEP_TYPE.AVAILABLE_PLUGINS_INFO,
                            'bi-exclamation': step.stepType === STEP_TYPE.INITIAL_PLAN,
                            'bi-calendar4-event': step.stepType === STEP_TYPE.TASK,
                            'bi-clipboard-data': step.stepType === STEP_TYPE.PLUGIN_ACTION,
                            'bi-clipboard-check': step.stepType === STEP_TYPE.PLUGIN_ACTION_RESPONSE,
                            'bi-chat-right-quote': step.stepType === STEP_TYPE.TASK_OUTCOME,
                            'bi-brightness-high': step.stepType === STEP_TYPE.REVISED_PLAN,
                            'bi-chat-left-dots': step.stepType === STEP_TYPE.FINAL_ANSWER,
                            'bi-person-fill': step.stepType === STEP_TYPE.USER_REVIEW,
                          }"
                  class="bi mr-2"></i> {{ 'planner.' + step.stepType | translate }}</span>
                </div>
                <div class="col-6 text-right">
              <span [ngClass]="{
                            'bg-success': step.stepStatus === STEP_STATUS.USER_FINALIZED || step.stepStatus === STEP_STATUS.AUTO_FINALIZED,
                            'bg-primary': step.stepStatus === STEP_STATUS.CREATED || step.stepStatus === STEP_STATUS.IN_PROGRESS,
                            'bg-info': step.stepStatus === STEP_STATUS.USER_PENDING,
                            'bg-danger': step.stepStatus === STEP_STATUS.CANCELLED
                          }" class="badge ms-auto">{{ 'rag.' + step.stepStatus | translate }}</span>
                </div>
              </div>
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              @if (step.stepType == "") {
              } @else if (step.stepType === STEP_TYPE.USER_INTENT) {
                <app-user-intent [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"></app-user-intent>
              } @else if (step.stepType === STEP_TYPE.AVAILABLE_PLUGINS_INFO) {
                <app-available-plugins [step]="step"
                                       (loadTooling)="loadTooling(this.toolingId ??'')"></app-available-plugins>

              } @else if (step.stepType === STEP_TYPE.INITIAL_PLAN) {
                <app-initial-plan [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"></app-initial-plan>
              } @else if (step.stepType === STEP_TYPE.TASK) {
                <app-task-definition [step]="step"
                                     (loadTooling)="loadTooling(this.toolingId ??'')"></app-task-definition>
              } @else if (step.stepType === STEP_TYPE.PLUGIN_ACTION) {
                <app-plugin-action [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"></app-plugin-action>
              } @else if (step.stepType === STEP_TYPE.PLUGIN_ACTION_RESPONSE) {
                <app-plugin-action-response [step]="step"
                                            (loadTooling)="loadTooling(this.toolingId ??'')"></app-plugin-action-response>
              } @else if (step.stepType === STEP_TYPE.TASK_OUTCOME) {
                <app-task-outcome [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"></app-task-outcome>
              } @else if (step.stepType === STEP_TYPE.REVISED_PLAN) {
                <app-revised-plan [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"></app-revised-plan>
              } @else if (step.stepType === STEP_TYPE.USER_REVIEW) {
                <app-user-review [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"></app-user-review>
              } @else if (step.stepType === STEP_TYPE.FINAL_ANSWER) {
                <app-final-answer [step]="step"></app-final-answer>
              }

            </div>
          </div>
        </div>
      }

    </div>

}
@if (isLoading) {
  <div ngbAccordion class="m-4">
    <div ngbAccordionItem>
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton class="arrowhead-accordion-header">
          <div class="row w-100">
            <div class="col-6">

            </div>
            <div class="col-6 text-right">
              <div class="ragLoader"></div>
            </div>
          </div>
        </button>
      </h2>
    </div>
  </div>
}
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BaseRepository,
  PositoryErrorHandlerService,
  Path,
  RepoPath
} from 'ngpository';

@Injectable({
  providedIn: 'root'
})
export class ApiRepository extends BaseRepository<any>{
  static showLoader: boolean = true;

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('')
  getAny() {
    return super.get();
  }
  public setUrl(url:string) {
    console.log(url);
    const regExp = /https?:\/\/.*/gm;
    url = regExp.test(url) ? url : 'http://' + url;
    this.repoUrl = url;
    console.log( this.repoUrl);

  }
  public unSetUrl(){
    this.repoUrl = undefined;
  }



}

@if (toolingSteps && toolingSteps.length == tooling?.steps?.length && !isLoading) {
  <div ngbAccordion class="m-4" >
    @for (step of toolingSteps; track step;) {
      <div ngbAccordionItem [collapsed]="$index!=toolingSteps.length-1">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="arrowhead-accordion-header">
            <div class="row w-100">
              <div class="col-6">
                <span><i
                  [ngClass]="{
                            'bi-bezier': step.stepType === STEP_TYPE.USER_INTENT,
                            'bi-chat-left-quote': step.stepType === STEP_TYPE.RETRIEVAL,
                            'bi-gear-wide-connected': step.stepType === STEP_TYPE.ANSWER_GENERATION,
                            'bi-chat-left-dots': step.stepType === STEP_TYPE.FINAL_ANSWER,
                          }"
                  class="bi mr-2"></i> {{ 'rag.' + step.stepType | translate }}</span>
              </div>
              <div class="col-6 text-right">
              <span [ngClass]="{
                            'bg-success': step.stepStatus === STEP_STATUS.USER_FINALIZED || step.stepStatus === STEP_STATUS.AUTO_FINALIZED,
                            'bg-primary': step.stepStatus === STEP_STATUS.CREATED || step.stepStatus === STEP_STATUS.IN_PROGRESS,
                            'bg-info': step.stepStatus === STEP_STATUS.USER_PENDING,
                            'bg-danger': step.stepStatus === STEP_STATUS.CANCELLED
                          }" class="badge ms-auto">{{ 'rag.' + step.stepStatus | translate }}</span>
              </div>
            </div>
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            @if (step.stepType == "") {
            } @else if (step.stepType === STEP_TYPE.USER_INTENT) {
              <app-rag-user-intent [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"
                                   *ngIf="step.stepType==STEP_TYPE.USER_INTENT"></app-rag-user-intent>
            } @else if (step.stepType === STEP_TYPE.RETRIEVAL) {
              <app-retrieval [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"
                             *ngIf="step.stepType==STEP_TYPE.RETRIEVAL"></app-retrieval>
            } @else if (step.stepType === STEP_TYPE.ANSWER_GENERATION) {
              <app-answer-generation [step]="step" (loadTooling)="loadTooling(this.toolingId ??'')"
                                     *ngIf="step.stepType==STEP_TYPE.ANSWER_GENERATION"></app-answer-generation>
            } @else if (step.stepType === STEP_TYPE.FINAL_ANSWER) {
              <app-rag-final-answer [step]="step" *ngIf="step.stepType==STEP_TYPE.FINAL_ANSWER"></app-rag-final-answer>
            }

          </div>
        </div>
      </div>
    }

  </div>
}
@if (isLoading) {
  <div ngbAccordion class="m-4">
    <div ngbAccordionItem>
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton class="arrowhead-accordion-header">
          <div class="row w-100">
            <div class="col-6">

            </div>
            <div class="col-6 text-right">
              <div class="ragLoader"></div>
            </div>
          </div>
        </button>
      </h2>
    </div>
  </div>
}

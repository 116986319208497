<div class="chat-bubble-container">
  <div class="chat-bubble">
    <div *ngIf="base64Image && this.message?.attachment!=''" class="card" style="width: 18rem;">
      <img [src]="base64Image" class="card-img-top" alt="Image" >
    </div>
    <p>{{ message?.rawData }}</p>
    @for (guard of Object.keys(message?.inputGuardrail!.properties); track guard){
      @if (message?.inputGuardrail!.properties[guard] && message?.inputGuardrail!.properties[guard].validation_passed){
        <i class="bi bi-shield-check mr-1" data-bs-toggle="tooltip" data-bs-placement="top" [title]="guard"></i>
      } @else if (message?.inputGuardrail!.properties[guard] && !message?.inputGuardrail!.properties[guard].validation_passed  && message?.inputGuardrail!.properties[guard].validation_passed!=undefined){
        <i class="bi bi-shield-x mr-1" data-bs-toggle="tooltip" data-bs-placement="top" [title]="guard+ ': '+ message?.inputGuardrail!.properties[guard].error"></i>
      }
    }
  </div>
  <img src="assets/images/thumbnail.png" alt="Thumbnail" class="chat-thumbnail">
</div>

<div class="row mt-3 mr-3">
</div>
<div class="tableContainer" style="margin:30px">

  <table *ngIf="this.templateList" datatable [dtOptions]="dtOptions" class="row-border hover">
    <thead>
    <tr>
      <th>{{"template.description" | translate}}</th>
      <th>{{"template.version" | translate}}</th>
      <th>{{"template.label" | translate}}</th>
      <th>{{"template.created" | translate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr  *ngFor="let template of this.templateList?.templates">
      <td>{{ template.description }}</td>
      <td>{{ template.version }}</td>
      <td>{{ template.label }}</td>
      <td>{{ template.createdOn | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      <td>
        <fa-icon [icon]="faEye" (click)="openUploadDialog(template)"></fa-icon>
      </td>
    </tr>
    </tbody>
  </table>

</div>

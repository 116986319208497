import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {PlannerRepository} from "../../../repositories/planner.repository";
import {ActivatedRoute} from "@angular/router";
import {STEP_STATUS} from "../../../models/enums";

@Component({
  selector: 'app-task-definition',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, FormsModule],
  templateUrl: './task-definition.component.html',
  styleUrl: './task-definition.component.css'
})
export class TaskDefinitionComponent {
  isEditing = false;
  orgiginalText = "";
  toolingId: string | null = "";
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();

  constructor(private plannerRepository: PlannerRepository,private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('plannerId');
    });
  }
  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.orgiginalText = this.step.action.rawData;
    }
  }


  save() {
    this.isEditing = false;
  }

  discard() {
    this.isEditing = false;
    this.step.action.rawData = this.orgiginalText;
  }

  next(){
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.plannerRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }

  protected readonly STEP_STATUS = STEP_STATUS;
}

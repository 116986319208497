<div class="modal-header">
  <h5 class="modal-title">PDF Preview</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="error">
    <p>Error loading PDF. Please check the URL and try again.</p>
  </div>
  <div *ngIf="!error" class="pdfContainer">
    <pdf-viewer [src]="documentUrl" [render-text]="true" style="display: block;" (error)="handlePdfError($event)" [page]="page"></pdf-viewer>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {PlannerRepository} from "../../../repositories/planner.repository";
import {STEP_STATUS, STEP_TYPE} from "../../../models/enums";
import {STEP_STATE} from "@angular/cdk/stepper";

@Component({
  selector: 'app-user-intent',
  standalone: true,
  imports: [ReactiveFormsModule, NgIf, FormsModule],
  templateUrl: './user-intent.component.html',
  styleUrl: './user-intent.component.css'
})
export class UserIntentComponent {
  isEditing = false;
  markdownText = '';
  editedText = this.markdownText;
  toolingId: string | null = "";
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();
  constructor(private plannerRepository: PlannerRepository,private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('plannerId');
    });
  }
  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.editedText =  this.step.action.rawData;
    }
  }

  save() {
    this.step.action.rawData=  this.editedText;
    this.isEditing = false;
  }

  discard() {
    this.isEditing = false;
  }

  next(){
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.plannerRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }

  protected readonly STEP_TYPE = STEP_TYPE;
  protected readonly STEP_STATE = STEP_STATE;
  protected readonly STEP_STATUS = STEP_STATUS;
}

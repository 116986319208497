<div class="container mt-4">
  <div class="wrapper">
    <div #chatContainer *ngIf="chatMessages?.messages?.length; else noMessages" class="chat-container">
      <div *ngFor="let message of chatMessages?.messages"
           [ngClass]="{'assistant-message': message.messageType === messageTypes.ASSISTANT_MESSAGE, 'human-message': message.messageType === messageTypes.HUMAN_MESSAGE}">
        <app-assistant-message [message]="message" [chatId]="this.chatId"
                               *ngIf="message.messageType==messageTypes.ASSISTANT_MESSAGE"></app-assistant-message>
        <app-human-message [message]="message"
                           *ngIf="message.messageType==messageTypes.HUMAN_MESSAGE && message.messageId!=''"></app-human-message>
        <app-tool-selector (functionCallEvent)="callFunction($event)" [message]="message" [chatId]="this.chatId"
                           *ngIf="message.messageType==messageTypes.TOOL_SELECTOR"></app-tool-selector>
        <app-redacted-message [message]="message"
                              *ngIf="message.messageType==messageTypes.REDACTED && message.messageId!=''"></app-redacted-message>
        <app-loader-message [message]="message"
                            *ngIf="message.messageType==messageTypes.PLACEHOLDER"></app-loader-message>

      </div>
      @if(reloadTimeOutCount>=5){
      <app-local-error-message></app-local-error-message>
      }
    </div>
    <ng-template #noMessages>
      <p>No messages available.</p>
    </ng-template>
  </div>

  <div class="input-box d-flex align-items-center p-3">
    <div *ngIf="base64Image" class="card mb-3 me-3 mt-1" style="width: 8rem;">
      <img [src]="base64Image" class="card-img-top" alt="Image">
      <button
        class="btn btn-sm btn-outline-primary position-absolute top-0 start-100 translate-middle"
        (click)="removeImage()"
        aria-label="Remove image"
        style="border-radius: 50%;"
      >X
      </button>
    </div>
    <input type="file" accept=".jpg,.png" (change)="onFileSelected($event)" #fileInput style="display: none;"/>
    <button class="btn btn-link p-0 me-2" (click)="fileInput.click()" style="text-decoration: none;" aria-label="Attach file"><i class="fa fa-paperclip" style="font-size: 1.5em;"></i></button>
    <input [(ngModel)]="humanMessage" [disabled]="!chatId || isLoading" class="form-control me-2" placeholder="Type your message here..." (keydown.enter)="sendMessage()" style="flex-grow: 1;"/>

    <button class="btn btn-primary" (click)="sendMessage()" [disabled]="!humanMessage.trim() || !chatId" style="display: flex; align-items: center;">Send</button>
  </div>


</div>

@if (step) {
<div class="position-relative">
  <markdown  [innerHTML]="step.action.rawData" class=" p-3 mb-3" ></markdown>
  @if(step.stepStatus== STEP_STATUS.AUTO_FINALIZED && chatId){
    <button type="submit" class="btn btn-primary me-2"  (click)="backToChat()">Back to chat</button>
  } @else {
    <button type="submit" class="btn btn-primary me-2"  (click)="backToStartPlanner()">Back to planner</button>
  }
</div>
}

<div class="modal-header">
  @if (plugin.pluginId) {
  <h5 class="modal-title">{{"plugin.edit" | translate}}</h5>
  } @else {
  <h5 class="modal-title">{{"plugin.new" | translate}}</h5>
  }

  <button type="button" class="close unhighlight" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>
<div class="modal-body">
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">

    <div class="row">
      <div class="col-12">
        <h5>{{"plugin.urlSection" | translate}}</h5>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="url">{{"plugin.url" | translate}}</label>
          <input type="text" id="url" formControlName="url" class="form-control"/>

        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="url">{{"plugin.getManifest" | translate}}</label>
          <button type="button" class="btn btn-primary" (click)="callOpenApiDefinition()">{{"plugin.getApi" | translate}}</button>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="openapi">OpenAPI</label>
          <textarea id="openapi" formControlName="openapi" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-12">
        <h5>{{"plugin.manifestSection" | translate}}</h5>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="displayName">{{"plugin.displayName" | translate}}</label>
          <input type="text" id="displayName" formControlName="displayName" class="form-control"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="modelName">{{"plugin.modelName" | translate}}</label>
          <input type="text" id="modelName" formControlName="modelName" class="form-control"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="humanDescription">{{"plugin.humanDescription" | translate}}</label>
          <textarea id="humanDescription" formControlName="humanDescription" class="form-control"></textarea>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="modelDescription">{{"plugin.modelDescription" | translate}}</label>
          <textarea id="modelDescription" formControlName="modelDescription" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="col-12">
        <h5>{{"plugin.authenticationSection" | translate}}</h5>
      </div>
      <div class="col-12">
        <div class="form-group">
          <div class="radio-group">
            <div class="radio-item">
              <input type="radio" id="authMethodNone" formControlName="authMethod" value="none" />
              <label for="authMethodNone">None</label>
            </div>
            <div class="radio-item">
              <input type="radio" id="authMethodMtls" formControlName="authMethod" value="mtls" />
              <label for="authMethodMtls">mTLS</label>
            </div>
            <div class="radio-item">
              <input type="radio" id="authMethodApiKey" formControlName="authMethod" value="auth_token" />
              <label for="authMethodApiKey">API Key</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    @if (authMethod=="mtls") {
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="certificatePublicKey">{{"plugin.certificatePublicKey" | translate}}</label>
          <textarea id="certificatePublicKey" formControlName="certificatePublicKey" class="form-control"></textarea>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="certificatePrivateKey">{{"plugin.certificatePrivateKey" | translate}}</label>
          <textarea id="certificatePrivateKey" formControlName="certificatePrivateKey" class="form-control"></textarea>
        </div>
      </div>
    </div>}
    @if (authMethod=="auth_token") {
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="authHeader">{{"plugin.authHeader" | translate}}</label>
          <input type="text" id="authHeader" formControlName="authHeader" class="form-control" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="authToken">{{"plugin.authToken" | translate}}</label>
          <input type="text" id="authToken" formControlName="authTokeny" class="form-control" />
        </div>
      </div>
    </div>
    }
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose()">{{"general.cancel" | translate}}</button>
      <button type="submit" class="btn btn-primary"
              [disabled]="!uploadForm.valid">{{"general.upload" | translate}}</button>
    </div>
  </form>
</div>

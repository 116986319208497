import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgClass, NgFor} from "@angular/common";
import {STEP_STATUS} from "../../../models/enums";
import {PlannerRepository} from "../../../repositories/planner.repository";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-available-plugins',
  standalone: true,
  imports: [NgFor, NgClass],
  templateUrl: './available-plugins.component.html',
  styleUrl: './available-plugins.component.css'
})
export class AvailablePluginsComponent {
  toolingId: string | null = "";
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();

  constructor(private plannerRepository: PlannerRepository,private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('plannerId');
    });
  }
  toggleSelect(i: any,stepStatus: STEP_STATUS) {
    if(stepStatus == STEP_STATUS.USER_PENDING) {
      this.step.action.plugins[i].enabled = !this.step.action.plugins[i].enabled;
    }
  }
  next(){
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.plannerRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }
  protected readonly STEP_STATUS = STEP_STATUS;
}

<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container-fluid">
    <!-- Logo and brand -->
    <a class="nav-link" [routerLink]="['/chats']">
      <img src="assets/images/{{environment.logo}}" alt="Arrowhead" title="Arrowhead" class="navbar-logo" />
    </a>

    <!-- Toggle button for mobile -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Navbar content -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Left-aligned links -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" [routerLink]="['/chats']">{{ 'menu.chat' | translate }}</a>
        </li>
        <li  class="nav-item">
          <a class="nav-link" [routerLink]="['/documents']">{{ 'menu.documents' | translate }}</a>
        </li>
        <li *appHasRole="'copilot-admin-role'" class="nav-item">
          <a class="nav-link" [routerLink]="['/templates']">{{ 'menu.promts' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/plugins']">{{ 'menu.plugins' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/plan']">{{ 'menu.startPlanner' | translate }}</a>
        </li>
      </ul>


      <ul class="navbar-nav ms-auto me-3 userIcon">
        <li class="nav-item dropdown d-flex">
          <a class="nav-link pt-2" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div #jazziconContainer class="me-2"></div>
            <span><i class="bi bi-person personIcon"></i></span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown1">
            <li><a class="dropdown-item" href="#" (click)="updateUser($event)">{{ 'general.updateUser' | translate }}</a></li>
            <li><a class="dropdown-item" href="#" (click)="logout($event)">{{ 'general.logout' | translate }}</a></li>
          </ul>
        </li>
      </ul>

      <span class="timerDisplay ms-3">{{ formattedTime }}</span>
    </div>
  </div>
</nav>

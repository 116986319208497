import { Injectable } from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() { }

  setItem(key: string, value: any): Observable<void> {
    return new Observable<void>(observer => {
      try {
        localStorage.setItem(key, JSON.stringify(value));
        observer.next();
        observer.complete();
      } catch (error) {
        observer.error(error);
      }
    });
  }

  getItem<T>(key: string): Observable<T | null> {
    return new Observable<T | null>(observer => {
      try {
        const item = localStorage.getItem(key);
        if (item) {
          observer.next(JSON.parse(item));
        } else {
          observer.next(null);
        }
        observer.complete();
      } catch (error) {
        observer.error(error);
      }
    });
  }
}

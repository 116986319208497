import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseRepository, Path, PositoryErrorHandlerService, RepoPath} from 'ngpository';
import {environment} from "../../environments/environment";
import {PlannerModel} from "../models/plannerModel";
import {toolingRunMode} from "../models/enums";
import {FormDataService} from "../services/form-data.service";
import {KeycloakService} from "../auth/keycloak.service";

@RepoPath(environment.plannerServiceUrl)
@Injectable({
  providedIn: 'root'
})
export class PlannerRepository extends BaseRepository<any> {
  static showLoader: boolean = true;

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService, private formDataConverter: FormDataService, private keyCloakService: KeycloakService ) {
    super(httpClient, errorhandler);
  }

  @Path('/')
  getPlanners() {
    return super.get();
  }

  @Path('/')
  async newPlanner(message: string, file?: File) {
   // let user = await this.keyCloakService.getProfile();
    let data: PlannerModel = {runMode: toolingRunMode.MANUAL, userIntent: message};
    if (file) {
      data = {...data, inputAttachment: file};
    }
    let formData: FormData = this.formDataConverter.convertToFormData(data);
    return super.postFile(formData);
  }


  @Path('/{id}')
  getPlanner(id: string) {
    return super.get();
  }

  @Path('/{id}/steps')
  getPlannerSteps(id: string) {
    return super.get();
  }

  @Path('/{id}/steps/{toolingStepId}')
  getToolingStep(id: string, toolingStepId: string) {
    return super.get();
  }

  @Path('/{id}/steps/{toolingStepId}')
  patchToolingStep(id: string, toolingStepId: string, toolingStep: any) {
    return super.patch(toolingStep);
  }

  public setUrl(url: string) {
    const regExp = /https?:\/\/.*/gm;
    url = regExp.test(url) ? url : 'http://' + url;
    this.repoUrl = url;

  }

  public unSetUrl() {
    this.repoUrl = undefined;
  }


}

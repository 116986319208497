<div class="modal-header">
  <h5 class="modal-title">{{"document.partition."+partition.elementType | translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div [innerHTML]="partition.rawData"></div>
    <div class="modal-footer">
    </div>
</div>

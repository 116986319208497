import { Component } from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-local-error-message',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './local-error-message.component.html',
  styleUrl: './local-error-message.component.css'
})
export class LocalErrorMessageComponent {

  constructor() {}

  reloadPage() {
    window.location.reload();
  }
}

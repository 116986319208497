import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BaseRepository,
  PositoryErrorHandlerService,
  Path,
  RepoPath
} from 'ngpository';

@RepoPath('/planner')
@Injectable({
  providedIn: 'root'
})
export class PlannerRepository extends BaseRepository<any>{
  static showLoader: boolean = true;

  constructor(httpClient: HttpClient, errorhandler: PositoryErrorHandlerService) {
    super(httpClient, errorhandler);
  }

  @Path('/')
  getPlanners() {
    return super.get();
  }

  @Path('/{id}')
  getPlanner(id:string) {
    return super.get();
  }
  @Path('/{id}/steps')
  getPlannerSteps(id:string) {
    return super.get();
  }

  @Path('/{id}/steps/{toolingStepId}')
  getToolingStep(id:string,toolingStepId: string) {
    return super.get();
  }

  @Path('/{id}/steps/{toolingStepId}')
  patchToolingStep(id:string,toolingStepId: string, toolingStep: any) {
    return super.patch(toolingStep);
  }
  public setUrl(url:string) {
    const regExp = /https?:\/\/.*/gm;
    url = regExp.test(url) ? url : 'http://' + url;
    this.repoUrl = url;

  }
  public unSetUrl(){
    this.repoUrl = undefined;
  }


}

import {Component, OnInit} from '@angular/core';
import { faArrowUpFromBracket, faDownload} from '@fortawesome/free-solid-svg-icons';
import {CommonModule} from "@angular/common";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";


@Component({
  selector: 'app-document-chunk-edit',
  templateUrl: './document-chunk-edit.component.html',
  styleUrl: './document-chunk-edit.component.css',
  standalone: true,
  imports: [CommonModule,FontAwesomeModule,FormsModule,NgSelectModule],
})
export class DocumentChunkEditComponent implements OnInit{
  faArrowUpFromBracket = faArrowUpFromBracket;
  faDownload = faDownload;
  partitionTypes = [
    { id: 1, name: 'Abstract' },
    { id: 2, name: 'Title' },
    { id: 3, name: 'NarrativeText' },
    { id: 4, name: 'Image' }
  ];
  selectedPartitionType: number=1;

  ngOnInit(): void {

  }
}

import { chatMessage } from './chatMessage';

export class chatMessages {
  chatId: string;
  chatName: string;
  type: string;
  lastMessageExtract: string;
  createdAt: string;
  messages: chatMessage[];

  constructor(data: any) {
    this.chatId = data.chatId;
    this.chatName = data.chatName;
    this.type = data.type;
    this.lastMessageExtract = data.lastMessageExtract;
    this.createdAt = data.createdAt;
    this.messages = data.messages.map((msg: any) => new chatMessage(msg));
  }
}
export interface MessageData {
  userChoice: string;
  textInput: string;
  inputAttachment?: File;
}

import {Component, Input, OnInit} from '@angular/core';
import {chatMessage, GuardrailDetail} from "../../../models/chatMessage";
import {JsonPipe, KeyValuePipe, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-human-message',
  standalone: true,
  imports: [
    JsonPipe,
    KeyValuePipe,
    NgForOf,
    NgIf
  ],
  templateUrl: './human-message.component.html',
  styleUrl: './human-message.component.css'
})

export class HumanMessageComponent implements OnInit{
  @Input() message: chatMessage | undefined ;
  imageSrc: string | null = null;
  base64Image: string ="";

  ngOnInit(): void {
    if(this.message?.attachment) {
      this.base64Image = `data:image/jpeg;base64,${this.message?.attachment}`;
    }
  }
  trackGuard(index: number, guard: { key: string, value: GuardrailDetail }): string {
    return guard.key;
  }
  getValidationError(guard: { key: string, value: any }): boolean {
    return guard.value && guard.value.error;
  }
  displayImageFromResponse(base64String: string, format: 'png' | 'jpg' = 'png') {
    const mimeType = format === 'jpg' ? 'image/jpeg' : 'image/png';
    this.imageSrc = `data:${mimeType};base64,${base64String}`;
  }
  protected readonly GuardrailDetail = GuardrailDetail;
  protected readonly Object = Object;
  imageLoaded: boolean = false;


}

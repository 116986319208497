import {Injectable} from '@angular/core';
import * as YAML from 'yaml'

@Injectable({
  providedIn: 'root'
})
export class OpenapiParserService {

  constructor() {
  }

  async parseOpenApiSpecToYaml(openApiSpec: string): Promise<any> {
    try {
      const parsedSpec = YAML.parse(openApiSpec);
      return parsedSpec;
    } catch (error) {
      console.error('Error parsing OpenAPI Specification:', error);
      throw error;
    }
  }

  getTitle(spec: any): string {
    return spec?.info?.title || 'Title not found';
  }

  getDescription(spec: any): string {
    return spec?.info?.description || 'Description not found';
  }

  getVersion(spec: any): string {
    return spec?.info?.version || 'version not found';
  }

}

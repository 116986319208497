<div class="d-flex mb-3">
  @for (button of step?.action?.plugins; track button;) {
    <button
      class="badge"
      [ngClass]="{'badge-success': button.enabled, 'badge-secondary': !button.enabled}"
      (click)="toggleSelect($index,step.stepStatus)"
    >
      {{ button.displayName }}
    </button>
  }
</div>
@if(step.stepStatus== STEP_STATUS.USER_PENDING){
  <button type="submit" class="btn btn-primary me-2"  (click)="next()">Next</button>
}

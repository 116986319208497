import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import jazzicon from "jazzicon-ts";
import {StorageService} from "../../services/storage.service";
import {firstValueFrom, Subscription} from "rxjs";
import {KeycloakService} from "../../auth/keycloak.service";
import {environment} from "../../../environments/environment";
import {TimerService} from "../../services/timer.service";
import Swal from "sweetalert2";
import {STEP_TYPE} from "../../models/enums";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

})
export class HeaderComponent implements OnInit {
  @ViewChild('jazziconContainer', {static: true}) jazziconContainer!: ElementRef;
  private salt: string = '';
  timeLeft: number = environment.exitPeriod/1000; // 5 minutes in seconds
  interval: any;
  formattedTime: string = "";
  constructor(private storageService: StorageService, private keycloakService: KeycloakService, private timerService: TimerService ) {
  }

  async ngOnInit(): Promise<void> {
    await this.handleSalt().then(salt => {
      this.salt = salt;
    }).catch(err => {
      console.error('Error handling salt:', err);
    });
  //  this.jazziconContainer.nativeElement.appendChild(this.getAccountIcon(25, this.salt));

    this.startCountdown();
    this.updateFormattedTime(this.timeLeft);
    this.timerService.timerReset$.subscribe(() => {
      this.resetCountdown();
    });
  }

  async handleSalt(): Promise<string> {
    try {
      const salt = await firstValueFrom(this.storageService.getItem<{ addr: string }>('salt'));
      if (salt && salt.addr) {
        return salt.addr;
      } else {
        const addr = this.generateRandomString(10);
        await firstValueFrom(this.storageService.setItem('salt', {addr}));
        return addr;
      }
    } catch (err) {
      const addr = this.generateRandomString(10);
      await firstValueFrom(this.storageService.setItem('salt', {addr}));
      return addr;
    }
  }

  public getAccountIcon(size: number, salt: string): HTMLDivElement {


    const generator = salt.slice(2, 10);
    const seed = parseInt(generator, 16);
    return jazzicon(size, seed);
  }

  private generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public logout(event: Event) {
    event.preventDefault();
    this.keycloakService.logout().then(() => {
      console.log('Logged out successfully');

    }).catch((error: unknown) => {
      console.error('Logout failed:', error);
    });
  }
  startCountdown() {
    this.updateFormattedTime(this.timeLeft);
    this.interval = setInterval(() => {
      this.timeLeft--;

      if (this.timeLeft <= 0) {
        this.timerService.logout(); // Logout when the time runs out
        clearInterval(this.interval); // Stop the interval
      }

      this.updateFormattedTime(this.timeLeft);
    }, 1000); // Run the countdown every second
  }

  // Method to update the formatted time string (hh:mm:ss)
  updateFormattedTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    this.formattedTime =
      this.padNumber(hours) + ':' +
      this.padNumber(minutes) + ':' +
      this.padNumber(secs);
  }

  // Pad numbers with leading zero if necessary (e.g., 05 instead of 5)
  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  resetCountdown() {
    clearInterval(this.interval);
    this.timeLeft = environment.exitPeriod/1000;
    this.updateFormattedTime(this.timeLeft); // Reset display
    this.startCountdown(); // Restart the countdown
  }

  protected readonly STEP_TYPE = STEP_TYPE;

  updateUser(event: Event) {
    event.preventDefault();
    this.keycloakService.updateUser().then(() => {
      console.log('Redirect to user page was successful');

    }).catch((error: unknown) => {
      console.error('Redirect to user page failed:', error);
    });
  }
}

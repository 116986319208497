import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem, NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {JsonPipe} from "@angular/common";
import {STEP_STATUS} from "../../../models/enums";
import {RagRepository} from "../../../repositories/rag.repository";
import {ActivatedRoute, Router} from "@angular/router";

declare var bootstrap: any;

@Component({
  selector: 'app-rag-final-answer',
  standalone: true,
  imports: [
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    TranslateModule,
    JsonPipe
  ],
  templateUrl: './rag-final-answer.component.html',
  styleUrl: './rag-final-answer.component.css'
})
export class RagFinalAnswerComponent {
  @Input() step: any;
  processedText: SafeHtml | undefined;
  protected readonly STEP_STATUS = STEP_STATUS;
  chatId: string | null = "";

  constructor(private route: ActivatedRoute, private router: Router,private sanitizer: DomSanitizer) {
  }

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('chatId');
    });
    this.processedText = this.addTooltipsToGuids(this.step.action?.rawData);

  }

  backToChat() {
    this.router.navigate([`/chat/${this.chatId}`]);
  }

  ngAfterViewInit(): void {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  addTooltipsToGuids(text: string): SafeHtml {
    const guidRegex = /\[([0-9a-fA-F-]{36})\]/g;
    const processedText = text.replace(guidRegex, (match, guid) => {
      const tooltipData = this.getDataForGuid(guid);
      return `<span class="tooltip-guid" data-bs-toggle="tooltip" title="${tooltipData}">[${guid}]</span>`;
    });
    return this.sanitizer.bypassSecurityTrustHtml(processedText);
  }
  getDataForGuid(guid: string): string {
    if (this.step?.action?.references) {
      const matchingReference = this.step.action.references.find((ref: any) => ref.partitionId === guid);
      if (matchingReference) {
        return matchingReference.summary || 'Summary not available';
      }
    }
    return 'No matching reference found for GUID';
  }
}

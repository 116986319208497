import {Component, Input} from '@angular/core';
import {JsonPipe, NgIf} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-partition-table',
  standalone: true,
  imports: [
    JsonPipe,
    NgIf,
    ReactiveFormsModule,
    TranslateModule
  ],
  templateUrl: './partition-table.component.html',
  styleUrl: './partition-table.component.css'
})
export class PartitionTableComponent {
  @Input() partition!: any ;
  constructor(public activeModal: NgbActiveModal) {
  }
  onClose(): void {
    this.activeModal.close();
  }
}

import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {lastValueFrom, Subject} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {PlannerRepository} from "../../repositories/planner.repository";
import {Utilities} from "../../utilities/utilities";
import {Router, RouterLink} from "@angular/router";
import {KeycloakService} from "../../auth/keycloak.service";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {documentTypeEnum, PLANNER_STATUS, STEP_STATUS, STEP_TYPE} from "../../models/enums";
import {DataTablesModule} from "angular-datatables";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {HasRoleDirective} from "../../directives/has-role.directive";
import {ToKbPipe} from "../../pipes/kb-to-mb.pipe";
import {Config} from "datatables.net";
import * as net from "node:net";

@Component({
  selector: 'app-plan-page',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    DataTablesModule,
    DatePipe,
    FaIconComponent,
    HasRoleDirective,
    NgForOf,
    ToKbPipe,
    NgClass,
    RouterLink
  ],
  templateUrl: './plan-page.component.html',
  styleUrl: './plan-page.component.css'
})
export class PlanPageComponent implements OnInit{
  base64Image: string = "";
  selectedFile: File | null = null;
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  humanMessage: string = "";
  plannerList: any = [];
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  private dtElement: any;


  constructor(private keyCloakService: KeycloakService, private plannerRepository: PlannerRepository, private notificationService: NotificationService, private cdr: ChangeDetectorRef, private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true
    };

   // let user = await this.keyCloakService.getProfile();
    this.plannerRepository.getPlanners().subscribe((res: any) => {
      this.plannerList = res;
    });

    console.log(this.plannerList);


  }

  onFileSelected(event: Event): void {

    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      const file = input.files[0];
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        const reader = new FileReader();
        reader.onload = () => {
          this.base64Image = reader.result as string;
          this.cdr.detectChanges();
        };
        reader.readAsDataURL(this.selectedFile);
        this.selectedFile = file;
      } else {
        this.notificationService.showError("Error", "chat.errorFileUpload");
      }
    }
  }

  removeImage() {
    this.base64Image = "";
    this.selectedFile = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
    }
  }

  async sendMessage() {

    try {
      let newPlanner;
      if (this.selectedFile) {
        newPlanner = await lastValueFrom(
          await this.plannerRepository.newPlanner(this.humanMessage, this.selectedFile)
        );
      } else {
        newPlanner = await lastValueFrom(await this.plannerRepository.newPlanner(this.humanMessage));
      }
      await new Promise(resolve => setTimeout(resolve, 200));
      console.log(newPlanner);
      if(newPlanner.toolingId) {
        await this.router.navigate([`/planner/${newPlanner.toolingId}`]);
      }
    } catch (error) {
      console.error("Nem sikerült új plannert létrehozni:", error);
    } finally {
      this.base64Image = "";
      this.selectedFile = null;
      if (this.fileInput) {
        this.fileInput.nativeElement.value = "";
      }
    }
  }
  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      console.log("table event")
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  protected readonly faTrash = faTrash;
  protected readonly documentTypeEnum = documentTypeEnum;
  protected readonly STEP_STATUS = STEP_STATUS;
  protected readonly PLANNER_STATUS = PLANNER_STATUS;
  protected readonly STEP_TYPE = STEP_TYPE;
}

import { Component } from '@angular/core';
import {RagComponent} from "../../components/rag/rag.component";

@Component({
  selector: 'app-rag-page',
  standalone: true,
  imports: [
    RagComponent
  ],
  templateUrl: './rag-page.component.html',
  styleUrl: './rag-page.component.css'
})
export class RagPageComponent {

}

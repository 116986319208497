import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {DocumentRepository} from "../../../repositories/document.repository";
import {NotificationService} from "../../../services/notification.service";
import {Router} from "@angular/router";
import {documentPartitionType} from "../../../models/enums";

@Component({
  selector: 'app-add-partition',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    ReactiveFormsModule
  ],
  templateUrl: './add-partition.component.html',
  styleUrl: './add-partition.component.css'
})
export class AddPartitionComponent {
  @Input() documentId!: any ;
  uploadForm: FormGroup ;
  base64Image: string ="";
  selectedFile: File | undefined = undefined;
  constructor(private router: Router,public activeModal: NgbActiveModal,   private fb: FormBuilder, private cdr: ChangeDetectorRef, private docRepository: DocumentRepository,  private notificationService: NotificationService) {
    this.uploadForm = this.fb.group({
      textInput: [''],
      summary: ['', Validators.required],
      elementType: ['text', Validators.required],
      tableHtml: ['']
    });
  }
  removeDataUrlPrefix(base64String: string): string {
    return base64String.replace(/^data:image\/[a-zA-Z]+;base64,/, '');
  }
  onSubmit(): void {
    const {summary} = this.uploadForm.value;
    const {elementType} = this.uploadForm.value;
    const {textInput} = this.uploadForm.value;
    const {tableHtml} = this.uploadForm.value;
    let rawData=""
    if (this.uploadForm.valid) {
      if(elementType === documentPartitionType.TEXT) {
         rawData = textInput;
      }
      else if(elementType === documentPartitionType.FIGURE ){
        rawData = this.removeDataUrlPrefix(this.base64Image);
      }
      else if(elementType === documentPartitionType.TABLE ){
        console.log(tableHtml);
        rawData = tableHtml;
      }
     this.docRepository.addPartition(this.documentId, elementType, rawData, summary).subscribe(
        (response) => {
          console.log('Upload successful', response);
          this.notificationService.showSuccess("Siker", "document.partition.successPatch");
          this.activeModal.close(true);
         this.router.navigate([this.router.url]).then(() => {
            window.location.reload();
          });
        },
        (error) => {
          console.error('Upload failed', error);
        }
      );
    }
  }
  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    this.uploadForm.patchValue({ fileInput: this.selectedFile });
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        this.base64Image = reader.result as string;
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }
  onClose(): void {
    this.activeModal.close();
  }

  protected readonly documentPartitionType = documentPartitionType;
}

import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import {from, map, Observable} from "rxjs";
import { environment } from '../../environments/environment';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class KeycloakService {
  private keycloakAuth: any;

  constructor(private router: Router) {}

  initKeycloak(): Promise<boolean> {
    this.keycloakAuth = new Keycloak({
      url: environment.keycloakUrl,
      realm: environment.keycloakRealm,
      clientId: environment.keycloakClientId,
    });

    return this.keycloakAuth.init({ onLoad: 'login-required',  checkLoginIframe: false })
      .then((authenticated: boolean) => {
        console.log(`Initialized Keycloak, authenticated: ${authenticated}`);
        return authenticated;
      }).catch((error: any) => {
        console.error('Error during Keycloak initialization', error);
        throw error;
      });
  }


  isLoggedIn(): Observable<boolean> {
    return from(Promise.resolve(!!this.keycloakAuth.token && !this.keycloakAuth.isTokenExpired()));
  }


  login() {
    return this.keycloakAuth.login();
  }

  logout() {
    return this.keycloakAuth.logout();
  }

  async getProfile() {
    return this.keycloakAuth.loadUserProfile();
  }

  getToken(): Promise<string> {
    return Promise.resolve(this.keycloakAuth.token);
  }

  renewToken(): Promise<string> {
    return Promise.resolve(this.keycloakAuth.updateToken(60));
  }

  updateUser() {
    return this.keycloakAuth.accountManagement();
  }
  getUserRoles(): string[] {
    if (!this.keycloakAuth || !this.keycloakAuth.authenticated) {
      return [];
    }

    const tokenParsed = this.keycloakAuth.tokenParsed;
    let roles: string[] = [];
    if (tokenParsed && tokenParsed.realm_access && Array.isArray(tokenParsed.realm_access.roles)) {
      roles = roles.concat(tokenParsed.realm_access.roles);
    }
    const clientId = environment.keycloakClientId;
    if (tokenParsed && tokenParsed.resource_access && tokenParsed.resource_access[clientId] && Array.isArray(tokenParsed.resource_access[clientId].roles)) {
      roles = roles.concat(tokenParsed.resource_access[clientId].roles);
    }

    return roles;
  }
  hasRole(role: string): boolean {
    const roles = this.getUserRoles();
    return roles.includes(role);
  }
  checkKeycloakLoginValidity(){
    if (this.keycloakAuth.authenticated) {
      const tokenParsed = this.keycloakAuth.tokenParsed;
      if (tokenParsed) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        const expiresIn = tokenParsed.exp - currentTime; // Remaining validity in seconds
        console.log(`Token expires in ${expiresIn} seconds`);
      } else {
        console.warn('Token information not available');
      }
    } else {
      console.warn('User not authenticated');
    }
  }
}

@if (!isEditing) {
  <div class="position-relative">
    <div [innerHTML]="step.action?.rawData" class="border p-3 mb-3"></div>
    @if (step.stepStatus == STEP_STATUS.USER_PENDING) {
      <i class="bi bi-pencil position-absolute top-0 end-0 m-2" (click)="toggleEdit()" style="cursor: pointer;"></i>
      <button type="submit" class="btn btn-primary me-2" (click)="next()">Next</button>
    }
  </div>
}
@if (isEditing) {
  <div class="border p-3">
    <form (ngSubmit)="save()">
      <div class="mb-3">
        <textarea [(ngModel)]="step.action.rawData" name="editedText" class="form-control" rows="5"></textarea>
      </div>
      <button type="submit" class="btn btn-primary me-2" (click)="save()">Save</button>
      <button type="button" class="btn btn-secondary" (click)="discard()">Discard</button>
    </form>
  </div>
}



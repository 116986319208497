<div class="chat-bubble-container">
  <div class="chat-bubble">
    <p>{{ message?.rawData }}</p>
    @for (guard of Object.keys(message?.inputGuardrail!.properties); track guard){
      @if (message?.inputGuardrail!.properties[guard] && message?.inputGuardrail!.properties[guard].validation_passed){
        <i class="bi bi-shield-check mr-1" data-bs-toggle="tooltip" data-bs-placement="top" [title]="guard"></i>
      } @else{
        <i class="bi bi-shield-x" data-bs-toggle="tooltip" data-bs-placement="top" [title]="guard+ ': '+ message?.inputGuardrail!.properties[guard].error"></i>
      }
    }
  </div>
  <img src="assets/images/thumbnail.png" alt="Thumbnail" class="chat-thumbnail">
</div>

import {Component, Input} from '@angular/core';
import {JsonPipe, KeyValuePipe, NgForOf} from "@angular/common";
import {chatMessage, GuardrailDetail} from "../../../models/chatMessage";

@Component({
  selector: 'app-redacted-message',
  standalone: true,
  imports: [
    JsonPipe,
    KeyValuePipe,
    NgForOf
  ],
  templateUrl: './redacted-message.component.html',
  styleUrl: './redacted-message.component.css'
})

export class RedactedMessageComponent {
  @Input() message: chatMessage | undefined ;
  trackGuard(index: number, guard: { key: string, value: GuardrailDetail }): string {
    return guard.key;
  }
  getValidationError(guard: { key: string, value: any }): boolean {
    return guard.value && guard.value.error;
  }

  protected readonly GuardrailDetail = GuardrailDetail;
  protected readonly Object = Object;
}

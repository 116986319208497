import {Component} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DocumentRepository} from "../../repositories/document.repository";
import {TranslateModule} from "@ngx-translate/core";
import {NotificationService} from "../../services/notification.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-upload-document',
  standalone: true,
  imports: [ReactiveFormsModule,TranslateModule],
  templateUrl: './upload-document.component.html',
  styleUrl: './upload-document.component.css',

})
export class UploadDocumentComponent {
  uploadForm: FormGroup;
  selectedFile: File | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    private docRepository: DocumentRepository,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private router: Router
  ) {
    this.uploadForm = this.fb.group({
      displayName: ['', Validators.required],
      description: ['', Validators.required],
      file: [null, Validators.required]
    });
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    this.uploadForm.patchValue({ file: this.selectedFile });
  }

  onSubmit(): void {
    if (this.uploadForm.valid && this.selectedFile) {
      const { displayName, description } = this.uploadForm.value;
      this.docRepository.save(this.selectedFile,true, displayName, description).subscribe(
        (response) => {
          console.log('Upload successful', response);
          this.notificationService.showSuccess("Siker","document.successUpload");
          this.activeModal.close(true);
          this.router.navigate([this.router.url]).then(() => {
            window.location.reload();
          });
        },
        (error) => {
          console.error('Upload failed', error);
        }
      );
    }
  }

  onClose(): void {
    this.activeModal.close();
  }
}

import { Injectable } from '@angular/core';
import { Constants } from '../../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() {
  }

  static getClientLanguage() {
    const lang = navigator.language.split('-');
    if (Constants.SYSTEM_LANGUAGES.indexOf(lang[0]) > -1) {
      console.log(lang[0]);
      return lang[0];
    } else {
      console.log('default');
      return Constants.DEFAULT_LANGUAGE;
    }
  }

}

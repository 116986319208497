import {Component, Input, OnInit} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-toc-viewer',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './toc-viewer.component.html',
  styleUrl: './toc-viewer.component.css'
})
export class TocViewerComponent implements OnInit {
  @Input() toc!: any;

  constructor(public activeModal: NgbActiveModal) {
  }

  onClose(): void {
    this.activeModal.close();
  }

  ngOnInit(): void {
  }

}

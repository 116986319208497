import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../services/notification.service";
import {PromtRepository} from "../../repositories/promt.repository";
import {Router} from "@angular/router";
import {JsonPipe} from "@angular/common";
import {ToKbPipe} from "../../pipes/kb-to-mb.pipe";

@Component({
  selector: 'app-view-template',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    JsonPipe,
    ToKbPipe
  ],
  templateUrl: './view-template.component.html',
  styleUrl: './view-template.component.css'
})
export class ViewTemplateComponent {
  @Input() template!: any;

  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  isJsonObject(data: any): boolean {
    if (typeof data === 'string') {
      try {
        const parsed = JSON.parse(data);
        return typeof parsed === 'object' && parsed !== null;
      } catch (e) {
        return false;
      }
    }
    return typeof data === 'object' && data !== null;
  }

  onClose(): void {
    this.activeModal.close();
  }
}

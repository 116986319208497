<ng-container >
  <div class="row" style="margin:30px">
    <div class="col-12"><h1>Edit partition</h1></div>
    <div class="col-12">
      <p><span class="h2p">Raw partition</span><fa-icon [icon]="faArrowUpFromBracket"></fa-icon><fa-icon
        [icon]="faDownload"></fa-icon></p>
      <div class="row">
        <div class="col-12">
          <textarea  class="w-100" placeholder="Revision of the Arrowhead Framework for Inter-Cloud Com - municatoins \n \n"></textarea>
        </div>
      </div>
    </div>

    <div class="col-12 mt-5">
      <span class="h2p ">Partition details</span>
      <div class="row mt-2">
        <div class="col-6">
          <div class="border-container h-100">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="imgRadio" id="idxImg" value="IDX_IMG" checked>
              <label class="form-check-label" for="idxImg">
                IDX_IMG
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="imgRadio" id="idxImg2" value="IDX_IMG2" checked>
              <label class="form-check-label" for="idxImg2">
                IDX_IMG2
              </label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <textarea  class="w-100" placeholder="Vector values" disabled></textarea>
        </div>
        <div class="col-3 mt-3">
        <ng-select [items]="partitionTypes"
                   bindLabel="name"
                   bindValue="id"
                   placeholder="PARTITION TYPE: "
                   [(ngModel)]="selectedPartitionType">
        </ng-select>
        </div>
      </div>
    </div>

  </div>
  <div class="row" style="margin:30px">
    <div class="col-12 text-right">
      <button class="btn  btn-outline-primary ml-2">No reembedding</button>
      <button class="btn btn-outline-dark ml-2">Reembed partition</button>
      <button class="btn btn-dark ml-2">Cancel</button>
      <button class="btn btn-primary ml-2">Save changes</button>
    </div>
  <ng-container>

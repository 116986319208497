import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem, NgbCollapse
} from "@ng-bootstrap/ng-bootstrap";
import {UserIntentComponent} from "../plannerComponents/user-intent/user-intent.component";
import {AvailablePluginsComponent} from "../plannerComponents/available-plugins/available-plugins.component";
import {InitialPlanComponent} from "../plannerComponents/initial-plan/initial-plan.component";
import {TaskDefinitionComponent} from "../plannerComponents/task-definition/task-definition.component";
import {PluginActionComponent} from "../plannerComponents/plugin-action/plugin-action.component";
import {
  PluginActionResponseComponent
} from "../plannerComponents/plugin-action-response/plugin-action-response.component";
import {TaskOutcomeComponent} from "../plannerComponents/task-outcome/task-outcome.component";
import {RevisedPlanComponent} from "../plannerComponents/revised-plan/revised-plan.component";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "../../services/loader.service";
import {lastValueFrom} from "rxjs";
import {PlannerRepository} from "../../repositories/planner.repository";
import {STEP_STATUS, STEP_TYPE} from "../../models/enums";
import {JsonPipe, NgClass, NgIf} from "@angular/common";
import {AnswerGenerationComponent} from "../ragComponents/answer-generation/answer-generation.component";
import {RagFinalAnswerComponent} from "../ragComponents/rag-final-answer/rag-final-answer.component";
import {RagUserIntentComponent} from "../ragComponents/rag-user-intent/rag-user-intent.component";
import {RetrievalComponent} from "../ragComponents/retrieval/retrieval.component";
import {FinalAnswerComponent} from "../plannerComponents/final-answer/final-answer.component";
import {UserReviewComponent} from "../plannerComponents/user-review/user-review.component";

@Component({
  selector: 'app-planner',
  standalone: true,
  imports: [AvailablePluginsComponent,
    InitialPlanComponent,
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionDirective,
    NgbAccordionHeader,
    NgbAccordionItem,
    NgbCollapse,
    PluginActionComponent,
    PluginActionResponseComponent,
    RevisedPlanComponent,
    TaskDefinitionComponent,
    TaskOutcomeComponent,
    TranslateModule,
    UserIntentComponent,
    RagUserIntentComponent,
    RetrievalComponent,
    RagFinalAnswerComponent,
    AnswerGenerationComponent,
    NgClass,
    JsonPipe,
    NgIf, FinalAnswerComponent, UserReviewComponent],
  templateUrl: './planner.component.html',
  styleUrl: './planner.component.css'
})
export class PlannerComponent implements OnInit, OnDestroy{
  toolingId: string | null = "";
  toolingSteps!: any[];
  tooling: any;
  isLoading: boolean = false;
  private reloadTimeout: any;
  @ViewChild('plannerContainer') private plannerContainer!: ElementRef;
  constructor(private plannerRepository: PlannerRepository, private router: Router, private route: ActivatedRoute, public loaderService: LoaderService) {}

  async ngOnInit(): Promise<void> {
    this.isLoading= true;
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('plannerId');
      console.log(this.toolingId);
      if (this.toolingId) {
        this.loadTooling(this.toolingId);
      }
    });
  }

  async loadTooling(id: string): Promise<void> {
    this.isLoading= true;
    this.plannerRepository.getPlanner(id).subscribe(
      async (tooling: any) => {
        this.tooling=tooling;
        const promises = tooling.toolingSteps.map(async (stepID: string, index: number) => {
          const toolingStep = await lastValueFrom(this.plannerRepository.getToolingStep(id, stepID)) ;
          tooling.toolingSteps[index] = toolingStep;
        });
        await Promise.all(promises);
        this.toolingSteps= tooling.toolingSteps;
        if (this.toolingSteps[this.toolingSteps.length-1].stepStatus ==STEP_STATUS.USER_FINALIZED || this.toolingSteps[this.toolingSteps.length-1].stepStatus==STEP_STATUS.AUTO_FINALIZED &&  this.toolingSteps[this.toolingSteps.length-1].stepType!=STEP_TYPE.FINAL_ANSWER){
         this.reloadTimeout = setTimeout(async () => {
            await this.loadTooling(id);
          }, 2000);
        }
        else{
          this.isLoading=false;

        }

      }
    );

      this.scrollToBottom();

  }
  ngOnDestroy(): void {
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
    }
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.plannerContainer.nativeElement.scroll({
          top:  this.plannerContainer.nativeElement.scrollHeight+3000,
          left: 0,
          behavior: 'smooth'
        });
        console.log(this.plannerContainer.nativeElement.scrollHeight);
      }, 1000);
    } catch(err) {
      console.error('Error in scrollToBottom:', err);
    }
  }
  protected readonly STEP_STATUS = STEP_STATUS;
  protected readonly STEP_TYPE = STEP_TYPE;
  ngAfterViewInit() {
  }
}

import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  constructor() {
  }

  convertToFormData(data: any) {
    const formData: FormData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    return formData;
  }
}

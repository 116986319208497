<div class="modal-header">
  <h5 class="modal-title">{{ "chat.vote" | translate }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="icon-container">
    @if (this.vote == VOTE_OPTION.THUMBS_UP) {
      <i class="bi bi-hand-thumbs-up mr-2" style="cursor:pointer" data-bs-toggle="tooltip" data-bs-placement="top" (click)="changeVote(VOTE_OPTION.THUMBS_DOWN)"
         title="{{'chat.thumbsUp' | translate }}"></i>
    } @else {
      <i class="bi bi-hand-thumbs-down mr-1"  style="cursor:pointer" data-bs-toggle="tooltip" data-bs-placement="top" (click)="changeVote(VOTE_OPTION.THUMBS_UP)"
         title="{{'chat.thumbsDown' | translate }}"></i>
    }

  </div>
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">

    <div class="form-group">
      <textarea id="description" class="form-control" formControlName="description"></textarea>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose()">{{ "general.cancel" | translate }}</button>
      <button type="submit" class="btn btn-primary">{{ "general.vote" | translate }}
      </button>
    </div>
  </form>
</div>

import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Utilities} from "../utilities/utilities";
import {PositoryErrorHandlerService} from "ngpository";
import {Observable, of} from "rxjs";
import {NotificationService} from "./notification.service";

@Injectable({
  providedIn: 'root'
})
  export class ErrorHandlerService extends PositoryErrorHandlerService{
  private readonly statusCodeTitleMap: { [key: number]: string } = {
    400: 'error.badRequest',
    401: 'error.unauthorized',
    403: 'error.forbidden',
    404: 'error.notFound',
    405: 'error.methodNotAllowed',
    408: 'error.requestTimeOut',
    409: 'error.conflict',
    500: 'error.internalServerError',
    501: 'error.notImplemented',
    502: 'error.badGateway',
    503: 'error.serviceUnavailable',
    504: 'error.gatewayTimeout',
    505: 'error.httpVersionNotSupported',
    420: 'error.constraintViolation',
    440: 'error.fileError',
    0:'error.unknown',
  };
  private readonly isProduction: boolean;

  constructor(private notificationService:NotificationService) {
    super();
    this.isProduction = Utilities.isProduction();
  }

  override handleError(error: HttpErrorResponse, caught: Observable<any>): Observable<HttpErrorResponse> {
    if (!this.isProduction) {
        // console.log(error);
      }
      if (error.error.attribute) {
        this.handleOwnError(error);
      } else {
        this.handleDefaultError(error);
      }
      return of(error);
  }

  private handleOwnError(err: any) {
    const title = this.statusCodeTitleMap[err.status];
    const message = err.error.attribute.message;
    this.notificationService.showError(title, message);
    if (!this.isProduction && err.error.attribute.devMessage) {
      this.notificationService.showError("error.devMessage", err.error.attribute.devMessage);
    }
  }

  private handleDefaultError(err: HttpErrorResponse) {
    const title = this.statusCodeTitleMap[err.status];
    const message = err.error.path && err.error.path.length>0 ? err.error.path : err.statusText;
    this.notificationService.showError(title, message);

  }
}

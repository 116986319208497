import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationService} from "../../services/notification.service";
import {VOTE_OPTION} from "../../models/enums";
import {ChatRepository} from "../../repositories/chat.repository";
import {Vote} from "../../models/chatMessage";

@Component({
  selector: 'app-vote',
  standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
  templateUrl: './vote.component.html',
  styleUrl: './vote.component.css'
})
export class VoteComponent {
  uploadForm: FormGroup;
  @Input() vote!: any;
  @Input() chatId!: any;
  @Input() messageId!: any;


  constructor(
    public activeModal: NgbActiveModal,
    private chatRepository: ChatRepository,
    private fb: FormBuilder,
    private notificationService: NotificationService,

  ) {
    this.uploadForm = this.fb.group({
      description: [''],
    });
  }
  onSubmit(): void {
    if (this.uploadForm.valid ) {
      const { description } = this.uploadForm.value;
      let voteObject = new Vote(this.vote, description);
      this.chatRepository.vote(this.chatId,this.messageId,voteObject).subscribe(
        (response) => {

          this.notificationService.showSuccess("Done","chat.successVote");
          this.activeModal.close(true);
        },
        (error) => {
          this.notificationService.showError("Error","chat.errorVote");
        }
      );
    }
  }

  onClose(): void {
    this.activeModal.close();
  }

  protected readonly VOTE_OPTION = VOTE_OPTION;

  changeVote(vote:VOTE_OPTION) {
   this.vote=vote;
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {JsonPipe, NgFor, NgIf} from "@angular/common";
import {STEP_STATUS} from "../../../models/enums";
import {PlannerRepository} from "../../../repositories/planner.repository";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-plugin-action-response',
  standalone: true,
  imports: [NgIf,JsonPipe,NgFor],
  templateUrl: './plugin-action-response.component.html',
  styleUrl: './plugin-action-response.component.css'
})
export class PluginActionResponseComponent {
  toolingId: string | null = "";
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();
  activeTab = 'status';

  constructor(private plannerRepository: PlannerRepository,private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('plannerId');
    });
  }
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  getHeaders() {
    return Object.keys(this.step.action.headers).map(key => ({
      key: key,
      value: this.step.action.headers[key]
    }));
  }
  getJson() {
    return JSON.parse(this.step.action.response);
  }

  next() {
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.plannerRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          this.loadTooling.emit();
        }
      );
    }
  }

  protected readonly STEP_STATUS = STEP_STATUS;
}

import {Component, Input, OnInit} from '@angular/core';
import {ChatRepository} from "../../../repositories/chat.repository";
import {ActivatedRoute, Router} from "@angular/router";
import {chatMessage} from "../../../models/chatMessage";


@Component({
  selector: 'app-loader-message',
  standalone: true,
  imports: [],
  templateUrl: './loader-message.component.html',
  styleUrl: './loader-message.component.css'
})
export class LoaderMessageComponent{
  chatId: string | null = "";
  @Input() message: chatMessage | undefined ;
  constructor(private chatRepository: ChatRepository, private router: Router, private route: ActivatedRoute) {
  }
  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('id');
    });
  }

  deleteStep() {
      this.chatRepository.deleteTooling(this.chatId, this.message?.messageId).subscribe();
  }
}

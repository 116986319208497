<div class="modal-header">
  <h5 class="modal-title">{{"document.partition."+partition.elementType+"Edit" | translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" *ngIf="partition">



    <div class="form-group">
      <label for="summary">{{"document.partition.summaryTitle" | translate}}</label>
      <input type="text" id="summary" class="form-control" formControlName="summary" required />
      <label for="textInput">{{"document.partition."+partition.elementType | translate}}</label>
      @if (partition.elementType=='text'){
      <textarea  id="textInput" class="form-control mt-2" formControlName="textInput" required style="height:500px"></textarea>
      } @else {
        <input type="text" id="textInput" class="form-control" formControlName="textInput" required />
      }

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose()">{{"general.cancel" | translate}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!uploadForm.valid">{{"general.upload" | translate}}</button>
    </div>
  </form>
</div>

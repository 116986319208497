<div class="chat-bubble-container">
  <img src="assets/images/Logo.svg" alt="Thumbnail" class="chat-thumbnail">
  <div class="chat-bubble">
    @if (message.rawData) {
      <p>{{ message.rawData }}</p>
    }
    @else {
      @if ( message.messageStatus == MessageStatus.MESSAGE_FINALIZED){
        <p>Tool response:</p>
        <p>{{ message.tooling.rawData }} </p>
      }
      @else {
        <p>{{ 'chat.noRawData' | translate }} </p>
      }
    }
    @if ( (message.messageStatus == MessageStatus.MESSAGE_FINALIZED || message.messageStatus == MessageStatus.TOOL_EXECUTION_PENDING) && message.userChoice != "USER_CANCEL" && message.tooling.status!="TOOL_ERROR"){
      <p>{{ 'tools.checkTool' | translate }}: <span><i
        style="cursor: pointer" (click)="jumpToTool(message.userChoice,message.tooling.toolingId)" class="bi mr-2 bi-caret-right-fill"></i></span></p>
    }
    <div class="buttonContainer d-flex flex-wrap" >
      <button class="btn btn-outline-light mt-2 me-2 d-inline-block" *ngFor="let tool of message.availableUserChoices"
              (click)="toolSelector(tool,message.messageId)">{{ 'tools.' + tool | translate }}
      </button>
    </div>
  </div>
</div>

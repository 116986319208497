import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {JsonPipe, NgIf} from "@angular/common";
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionHeader, NgbAccordionItem
} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {STEP_STATUS, STEP_TYPE} from "../../../models/enums";
import {STEP_STATE} from "@angular/cdk/stepper";
import {RagRepository} from "../../../repositories/rag.repository";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-rag-user-intent',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionHeader,
    NgbAccordionItem,
    TranslateModule,
    JsonPipe
  ],
  templateUrl: './rag-user-intent.component.html',
  styleUrl: './rag-user-intent.component.css'
})
export class RagUserIntentComponent {
  isEditing = false;
  markdownText = '';
  editedText = this.markdownText;
  toolingId: string | null = "";
  @Input() step: any;
  @Output() loadTooling: EventEmitter<void> = new EventEmitter<void>();
  constructor(private ragRepository: RagRepository,private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.toolingId = params.get('ragId');
    });
  }
  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.editedText =  this.step.action.rawData;
    }
  }

  save() {
    this.step.action.rawData=  this.editedText;
    this.isEditing = false;
  }

  discard() {
    this.isEditing = false;
  }

  next(){
    this.step.stepStatus="USER_FINALIZED";
    if(this.toolingId) {
      this.ragRepository.patchToolingStep(this.toolingId, this.step.toolingStepId, this.step).subscribe(
        async (toolingStepPatchResponse: any) => {
          console.log(toolingStepPatchResponse);
          this.loadTooling.emit();
        }
      );
    }
  }

  protected readonly STEP_TYPE = STEP_TYPE;
  protected readonly STEP_STATE = STEP_STATE;
  protected readonly STEP_STATUS = STEP_STATUS;
}

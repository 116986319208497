@if (step?.action) {

<div class="mt-3">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'url'" (click)="setActiveTab('url')">{{'planner.pluginAction.url' | translate}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'method'" (click)="setActiveTab('method')">{{'planner.pluginAction.method' | translate}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'params'" (click)="setActiveTab('params')">{{'planner.pluginAction.params' | translate}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'headers'" (click)="setActiveTab('headers')">{{'planner.pluginAction.headers' | translate}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [class.active]="activeTab === 'body'" (click)="setActiveTab('body')">{{'planner.pluginAction.body' | translate}}</a>
    </li>

  </ul>

  <div class="tab-content w-100">
    <div *ngIf="activeTab === 'url'" class="tab-pane active mt-3 w-100">
      <form [formGroup]="urlForm">
        <div class="input-group w-100">
          <input type="text" formControlName="url" class="form-control mw-100 w-100" placeholder="Enter URL" >
        </div>
      </form>
    </div>
    <div *ngIf="activeTab === 'method'" class="tab-pane active mt-3 w-100">
      <form [formGroup]="methodForm">
        <div class="input-group w-100">
          <select formControlName="method" class="form-control mw-100 w-100">
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
            <option value="PATCH">PATCH</option>
            <option value="OPTIONS">OPTIONS</option>
            <option value="HEAD">HEAD</option>
          </select>
        </div>
      </form>
    </div>
    <div *ngIf="activeTab === 'params'" class="tab-pane active mt-3 w-100">
      <form [formGroup]="paramsForm">
        <div formArrayName="params">
          <div *ngFor="let params of params.controls; let i = index" [formGroupName]="i" class="input-group mb-2">
              <input type="text" formControlName="key" class="form-control" placeholder="Key" >
              <input type="text" formControlName="value" class="form-control" placeholder="Value">
            @if(step.stepStatus== STEP_STATUS.USER_PENDING){
            <button type="button" class="btn" (click)="removeParams(i)">
              <i class="bi bi-trash"></i>
            </button>
            }

          </div>
        </div>
        @if(step.stepStatus== STEP_STATUS.USER_PENDING){
        <button type="button" class="btn btn-primary" (click)="addParams()">Add Param</button>
        }
      </form>
    </div>

    <div *ngIf="activeTab === 'headers'" class="tab-pane active mt-3 w-100">
      <form [formGroup]="headersForm">
        <div formArrayName="headers">
          <div *ngFor="let header of headers.controls; let i = index" [formGroupName]="i" class="input-group mb-2 w-100">
            <input type="text" formControlName="key" class="form-control" placeholder="Key">
            <input type="text" formControlName="value" class="form-control" placeholder="Value">
            @if(step.stepStatus== STEP_STATUS.USER_PENDING){
            <button type="button" class="btn" (click)="removeHeader(i)">
              <i class="bi bi-trash"></i>
            </button>
            }
          </div>
        </div>
        @if(step.stepStatus== STEP_STATUS.USER_PENDING){
        <button type="button" class="btn btn-primary" (click)="addHeader()">Add Header</button>
        }
      </form>
    </div>

    <div *ngIf="activeTab === 'body'" class="tab-pane active mt-3 w-100">
      <form [formGroup]="bodyForm">
        <textarea formControlName="body" class="form-control" rows="5" placeholder="Enter body content"></textarea>
      </form>
    </div>

  </div>
  @if(step.stepStatus== STEP_STATUS.USER_PENDING){
    <button class="btn btn-primary mt-3" (click)="next()">Next</button>
  }
</div>
}

<div class="modal-header">
  <h5 class="modal-title">{{"document.addPartition" | translate}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()" >

    <div class="form-group">
      <label>{{"document.partition.type" | translate}}</label>
      <div class="radio-group">
        <div class="radio-item">
          <input type="radio" id="typeText" formControlName="elementType" value="text" required checked/>
          <label for="typeText">{{'document.partition.textRadio' | translate}}</label>
        </div>
        <div class="radio-item">
          <input type="radio" id="typeImage" formControlName="elementType" value="figure" required />
          <label for="typeImage">{{'document.partition.imageTitle' | translate}}</label>
        </div>
        <div class="radio-item">
          <input type="radio" id="typeTable" formControlName="elementType" value="table" required />
          <label for="typeTable">{{'document.partition.tableTitle' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="summary">{{"document.partition.summaryTitle" | translate}}</label>
      <input type="text" id="summary" class="form-control" formControlName="summary" />
      @if (uploadForm.get('elementType')?.value === documentPartitionType.TEXT){
      <label for="textInput">{{"document.partition.text" | translate}}</label>
      <textarea id="textInput" class="form-control" formControlName="textInput" style="height:500px" ></textarea>
      }
      @if (uploadForm.get('elementType')?.value === documentPartitionType.TABLE){
      <label for="tableHtml">{{"document.partition.tableHtml" | translate}}</label>
        <textarea id="tableHtml" class="form-control" formControlName="tableHtml" style="height:500px" ></textarea>
      }
    </div>
    @if (uploadForm.get('elementType')?.value === documentPartitionType.FIGURE){
    <div class="form-group" >
      <label for="fileInput">{{"document.partitionFile" | translate}}</label>
      <input type="file" id="fileInput" class="form-control" (change)="onFileSelected($event)" accept=".png, .jpg, .jpeg, .svg" required />
    </div>
    }
    <div class="d-flex justify-content-center" *ngIf="base64Image">
      <div class="card" style="width: 18rem;">
        <img [src]="base64Image" class="card-img-top" alt="Image">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onClose()">{{"general.cancel" | translate}}</button>
      <button type="submit" class="btn btn-primary" [disabled]="!uploadForm.valid">{{"general.upload" | translate}}</button>
    </div>
  </form>
</div>

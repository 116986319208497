import {Component, Input} from '@angular/core';
import {STEP_STATUS} from "../../../models/enums";
import {PlannerRepository} from "../../../repositories/planner.repository";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-final-answer',
  standalone: true,
  imports: [],
  templateUrl: './final-answer.component.html',
  styleUrl: './final-answer.component.css'
})

export class FinalAnswerComponent {
  chatId: string | null = "";
  @Input() step: any;
  protected readonly STEP_STATUS = STEP_STATUS;
  constructor(private plannerRepository: PlannerRepository,private router: Router,private route: ActivatedRoute) {}
  async ngOnInit(): Promise<void> {
    this.route.paramMap.subscribe(params => {
      this.chatId = params.get('chatId');
    });
  }
  backToChat() {
    this.router.navigate([`/chat/${this.chatId}`]);
  }
}
